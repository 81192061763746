<template>
  <div class="wrap">
    <div class="w form-wrap fromWidthFlex">
      <h3>{{$t('exhibitors.booking')}}</h3>
      <el-form
        ref="form"
        class="form"
        :model="formData"
        :rules="rules"
        :inline="true"
        label-width="180px"
      >
        <el-form-item :label="$t('exhibitors.form.es')" prop="session">
          <el-select
            class="medium-width"
            v-model="formData.session"
            :placeholder="$t('exhibitors.form.select')"
          >
            <el-option
              v-for="item in sessionCount"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('exhibitors.form.cn')" prop="organization">
          <el-input class="medium-width" v-model="formData.organization"></el-input>
        </el-form-item>
        <el-form-item :label="$t('exhibitors.form.name')" prop="name">
          <el-input class="medium-width" v-model="formData.name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('exhibitors.form.position')" prop="job">
          <el-input class="medium-width" v-model="formData.position"></el-input>
        </el-form-item>
        <el-form-item :label="$t('exhibitors.form.sex')" prop="sex">
          <el-select
            v-model="formData.sex"
            class="medium-width"
            :placeholder="$t('exhibitors.form.selectSex')"
          >
            <el-option :label="$t('exhibitors.form.male')" value="1"></el-option>
            <el-option :label="$t('exhibitors.form.female')" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('exhibitors.form.phone')" prop="phone">
          <el-input class="medium-width" v-model="formData.phone"></el-input>
        </el-form-item>
        <el-form-item :label="$t('exhibitors.form.c_phone')" prop="unit_phone">
          <el-input class="medium-width" v-model="formData.unit_phone"></el-input>
        </el-form-item>
        <el-form-item :label="$t('exhibitors.form.nature')" prop="nature">
          <el-select
            v-model="formData.nature"
            class="medium-width"
            :placeholder="$t('exhibitors.form.com_nature')"
          >
            <el-option label="厂家" value="1"></el-option>
            <el-option label="经销商" value="2"></el-option>
            <el-option label="个体户" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('exhibitors.form.at')" prop="turnover">
          <el-input class="medium-width" v-model="formData.turnover"></el-input>
        </el-form-item>
        <el-form-item :label="$t('exhibitors.form.email')" prop="email">
          <el-input class="medium-width" v-model="formData.email"></el-input>
        </el-form-item>
        <el-form-item :label="$t('exhibitors.form.area')" prop="province">
          <el-select
            v-model="formData.province"
            class="small-width"
            :placeholder="$t('exhibitors.form.province')"
            @change="chooseProvince"
          >
            <el-option
              v-for="item in provinceData"
              :key="item.code"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
          <el-select
            v-model="formData.city"
            class="small-width ml20"
            :placeholder="$t('exhibitors.form.city')"
          >
            <el-option
              v-for="item in cityData"
              :key="item.code"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('exhibitors.form.addressDet')" prop="address">
          <el-input class="large-width" v-model="formData.address"></el-input>
        </el-form-item>
        <el-form-item :label="$t('exhibitors.form.brand')" prop="basic_brand">
          <el-select v-model="formData.basic_brand" multiple :placeholder="$t('exhibitors.form.selectArea')" style="width:400px">
            <el-option
              v-for="item in optionsExhibits"
              :key="item.id"
              :label="item.title"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 经营品牌 -->

        <div class="btn-wrap">
          <el-form-item>
            <el-button
              type="primary"
              class="subGet"
              @click="onSubmit"
              :loading="loading"
            >{{$t('exhibitors.form.save')}}</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { overIntentionGet, WillShowGet } from "@/api/index";
import { city } from "@/components/city.js";
export default {
  name: "reserveForm",
  components: {},
  data() {
    return {
      props: { multiple: true },
      loading: false,
      sessionCount: [],
      provinceData: [],
      provinceDataCity: [],
      cityData: [],
      tags: [],
      tagsArrL: [
        { name: "茶叶", type: "1" },
        { name: "茶器", type: "2" },
        { name: "茶机器", type: "3" }
      ],

      tagsArr: [
        { name: "定制包装", type: "" },
        { name: "定制包装2", type: "" },
        { name: "定制包装3", type: "" },
        { name: "定制包装4", type: "" },
        { name: "定制包装5", type: "" }
      ],
      dmListIndex: 0,
      dmListShow: false,
      formData: {
        session: "", // 参会场次
        organization: "", // 公司/机构名称
        name: "", // 姓名
        position: "", // 职位
        sex: "", // 性别
        phone: "",
        unit_phone: "",
        nature: "",
        turnover: "",
        email: "",
        province: "",
        city: "",
        address: "",
        basic_brand: "", // 存原始筛选的数据
        brand: ""
      },
      rulesFY: {
        // 英文语言包提示处理数据
        data: {
          address: ["address"],
          province: ["area"],
          session: ["cc"],
          organization: ["ci_name", "max50"],
          name: ["name", "max50"],
          phone: ["phone", "p_err"],
          unit_phone: ["tel", "t_err"],
          // sex: ["sex"],
          email: ["email", "e_err"]
        }
      },
      rules: {
        address: [
          { required: true, message: "请输入详细地址", trigger: "blur" }
        ],
        province: [{ required: true, message: "请选择地区", trigger: "blur" }],
        session: [{ required: true, message: "请选择场次", trigger: "blur" }],
        organization: [
          { required: true, message: "请输入公司/机构名称", trigger: "blur" },
          { max: 50, message: "最多输入50个字符", trigger: "blur" }
        ],
        name: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          { max: 50, message: "最多输入50个字符", trigger: "blur" }
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            required: true,
            pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/,
            message: "手机号格式不正确",
            trigger: "blur"
          }
        ],
        unit_phone: [
          { required: false, message: "请输入电话", trigger: "blur" },
          {
            required: false,
            pattern: /^[0-9]+$/,
            message: "电话格式不正确",
            trigger: "blur"
          }
        ],
        // sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        email: [
          { required: true, message: "请输入邮箱", trigger: "change" },
          { type: "email", message: "邮箱格式不正确", trigger: "blur" }
        ]
      },
      optionsExhibits: []
    };
  },
  created() {
    this.inittionGet();
    this.provinceDataCity = city;
    this.provinceData = city.filter(item => {
      return item.name.indexOf("省") > -1;
    });

    // 处理英文语言包验证提示
    if (this.$i18n.locale == "en") {
      let ruFy = this.rulesFY.data;
      let res = this.$i18n.messages[this.$i18n.locale].exhibitors.tips;
      for (let i in this.rules) {
        this.rules[i].forEach((li, ind) => {
          li.message = res[ruFy[i][ind]];
        });
      }
    }
  },
  methods: {
    chooseProvince(value) {
      this.formData.city = "";
      this.provinceDataCity.map(e => {
        //遍历数据
        if (value == e.name) {
          this.cityData = e.cityList;
          return;
        }
      });
    },
    inittionGet() {
      overIntentionGet().then(res => {
        const data = res.data;
        data.event_cate.map(sel => {
          this.sessionCount.push({
            label: sel.event_name,
            value: sel.id
          });
        });
        this.optionsExhibits = data.exhibitor_cate;
      });
    },
    onSubmit() {
      this.loading = true;
      this.$refs["form"].validate(valid => {
        if (valid) {
          // let arr = this.formData.basic_brand || [];
          // let objs = arr.map(m => m[1]);
          // let list = [];
          // this.optionsExhibits.map(ds => {
          //   ds.children.map(wq => {
          //     objs.map(ob => {
          //       if (wq.id === ob) {
          //         list.push(wq.title);
          //       }
          //     });
          //   });
          // });
          let datas = this.$myJson(this.formData);
          this.$set(datas,'emali',this.formData.email)
          this.$delete(datas,'email')
          // datas.brand = list;
          datas.sex = Number(datas.sex);
          for (let i in datas) {
            if (i.indexOf("basic_") > -1) {
              delete datas[i];
            }
          }
          // console.log('提交参数', this.$myJson(datas))
          // return
          WillShowGet(datas).then(res => {
            this.loading = false;
            // console.log(res,99999)
            this.$message({
              message: res.msg,
              type: res.code===200 ? "success" : "error"
            });
          });
        } else {
          this.loading = false;
          console.log("error submit!!");
          return false;
        }
      });
    },
    dmInputClick() {
      this.dmListShow = this.dmListShow ? false : true;
    },
    handleClose(tag) {
      this.tags.splice(this.tags.indexOf(tag), 1);
    },
    tabClick(obj, index) {
      this.dmListIndex = index;
      if (obj == 1) {
        this.tagsArr = [
          { name: "定制包装", type: "" },
          { name: "定制包装2", type: "" },
          { name: "定制包装3", type: "" },
          { name: "定制包装4", type: "" },
          { name: "定制包装5", type: "" }
        ];
      } else if (obj == 2) {
        this.tagsArr = [
          { name: "通版包装", type: "" },
          { name: "通版包装2", type: "" },
          { name: "通版包装3", type: "" },
          { name: "通版包装4", type: "" },
          { name: "通版包装5", type: "" }
        ];
      } else if (obj == 3) {
        this.tagsArr = [
          { name: "简介包装", type: "" },
          { name: "简介包装2", type: "" },
          { name: "简介包装3", type: "" },
          { name: "简介包装4", type: "" },
          { name: "简介包装5", type: "" }
        ];
      }
    },
    tabItem(obj) {
      this.tags.push(obj);
    }
  },
  mounted() {}
};
</script>

<style scoped  lang="scss">
.fromWidthFlex{
  width:84%!important;
}
.subGet {
  background-color: #0e9b32;
  border: 1px solid #0e9b32;
}

.form-wrap {
  margin-bottom: 200px;

  h3 {
    font-size: 32px;
    font-weight: 500;
    color: #333333;
    text-align: center;
    padding: 55px 0;
  }
  /deep/ .el-form-item__label {
    line-height: 1.2em;
    padding-top: 0.8em;
  }
}
.btn-wrap {
  text-align: center;
}
.small-width {
  width: 190px;
}
.medium-width {
  width: 400px;
}
.large-width {
  width: 650px;
}
.ml20 {
  margin-left: 20px;
}
.dm-my-input-box {
  position: relative;

  .dm-my-input {
    background-color: #ffffff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: none;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 650px;
    // overflow-x: auto;
    cursor: pointer;
  }
  .dm-my-tag {
    margin-left: 10px;

    &:first-child {
      margin-left: 0;
    }
  }

  .dm-list {
    position: absolute;
    top: 40px;
    left: 0;
    width: 650px;
    overflow: hidden;
    z-index: 2;

    li {
      position: relative;
      width: 325px;
      height: 35px;
      font-size: 16px;
      color: #333333;
      line-height: 35px;
      text-indent: 21px;
      cursor: pointer;
      user-select: none;
    }

    .fl {
      background: #fff;

      li {
        &::before {
          position: absolute;
          left: 0;
          top: 0;
          content: "";
          width: 2px;
          height: 100%;
          background: #0e9b32;
          display: none;
        }

        &.active::before {
          display: block;
        }
      }
    }
    .fr {
      background: #f1f1f1;

      li {
        &:hover {
          color: #fff;
          background: #0e9b32;
        }
      }
    }
  }
}
</style>